#root {
  white-space: pre-line;
}

ion-title,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
  color: var(--ion-text-color);
  font-family: var(--title-font) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 6px;
}

h3 {
  font-size: 20px;
}

ion-menu ion-label,
ion-label {
  font-weight: 600 !important;
  color: var(--ion-text-color);
  font-family: var(--title-font) !important;
}

ion-label p {
  font-weight: 400;
  font-family: var(--content-font) !important;
}

.content-font {
  font-family: var(--content-font) !important;
}

/* Separate icon from text inside a button */
ion-button .svg-inline--fa {
  margin-right: 6px !important;
  /* color:#ffffff */
}

ion-button.no-icon-margin .svg-inline--fa {
  margin-right: 0px !important;
}

.no-bottom-margin {
  margin-bottom:0!important;
}

.header-md::after {
  display: none;
}

.menu-inner .item {
  background-color: #5ebaeb;
}

ion-menu .svg-inline--fa {
  /* color: var(--ion-color-medium); */
}

/* Ion-list & Ion-item styles */
ion-item .svg-inline--fa {
  color: var(--ion-color-secondary);
}

.pl_case_name .svg-inline--fa {
  margin-right:0px;
}

/* to match with icons onn data tables */
ion-list .svg-inline--fa,
.settings-menu .svg-inline--fa {
  width: 20px !important;
  margin: 0 auto;
  margin-right: 16px;
}

ion-item {
  --border-width: 0;
  --inner-border-width: 0;
}

ion-list.lines ion-item {
  border-bottom: 1px solid var(--rashtan-border-color);
}

ion-list.lines ion-item:last-child {
  border-bottom: none;
}

.list-md-lines-full .item, .list-md .item-lines-full {
  --border-radius: 5px;
}

@media only screen and (max-width: 600px) {

  /* removing padding on small screens to make more space for content */
  ion-list ion-item {
    --inner-padding-end: 0px;
    --padding-start: 0px
  }
}

/* END Ion-list & Ion-item styles */

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
  --padding-start: 0;
}

/* Remove background transitions for switching themes */
ion-menu ion-item {
  --transition: none;
}

ion-item.selected {
  /* --color: var(--ion-color-primary); */
  /* background-color: #fff; */
  background-color: rgba(255, 255, 255, 0.25);
}

ion-row.invoiceItemsHeader {
  font-size: 0.7em;
  font-weight: bold;
}

.invoice-total-col {
  padding-top: 25px;
}

.invoice-total-col .large {
  border: none !important;
}

.invoice-header-row {
  padding: 0 10px;
  border: 1px solid var(--rashtan-border-color) !important;
  ;
  border-radius: 5px;
  background: #f9f9f9;
  font-size: 1em;
}

.inv-delete-button {
  margin-top: -3px;
  float: right;
}

.white-icon {
  color: #ffffff !important;
}

.mediumColor {
  color: var(--ion-color-medium) !important;
}

.tertiaryColor {
  color: var(--ion-color-tertiary) !important;
}

.secondaryColor {
  color: var(--ion-color-secondary) !important;
}

.successColor {
  color: var(--ion-color-success) !important;
  border-color: var(--ion-color-success) !important;
}

.successBgColor {
  background: rgb(42 172 105 / 15%) !important;
}

.warningColor {
  color: var(--ion-color-warning) !important;
  border-color: var(--ion-color-warning) !important;

}

.warningBgColor {
  background: rgb(230 180 15 / 15%) !important;
}

.tertiaryColor {
  color: var(--ion-color-tertiary) !important;
}

.primaryColor {
  color:var(--ion-color-primary) !important
}

.primaryBgColor {
  background: rgb(0 173 181 / 15%) !important;
}

.textColor {
  color: var(--ion-text-color) !important;
}

.greyColor {
  color: var(--ion-text-color) !important; 
  border-color: #848484 !important; 
}

.greyBgColor {
  background: #f5f5f5 !important;
}

.whiteColor {
  color: var(--ion-color-primary-contrast) !important;
}

.dangerColor {
  color: var(--ion-color-danger) !important;
  border-color: var(--ion-color-danger) !important;
}

.dangerBgColor {
  /* background: var(--ion-color-danger) !important; */
  background: rgb(235 68 90 / 15%)!important;
}

.pointer {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  
  .fc-daygrid-event .dangerBgColor, .fc-daygrid-event .successBgColor, .fc-daygrid-event .warningBgColor {
    background: none!important;
  }

}



/*
* Form Styles
*/
ion-input {
  border: solid 1px #e4e4e4 !important;
  margin-top: 6px;
  border-radius: 8px !important;
}

ion-input input {
  padding: 12px 14px !important;
}

ion-textarea textarea {
  border: solid 1px #e4e4e4 !important;
  margin-top: 10px;
  border-radius: 8px !important;
  padding: 12px 14px !important;
}

ion-button {
  --box-shadow: none;
  --border-radius: 8px;
}

/* removing padding on forms in modals */
ion-modal ion-item {
  --inner-padding-end: 0px;
  --padding-start: 0px
}

ion-modal form ion-button {
  --ion-margin: 0;
  margin-top: 14px !important;
}

/*
* END Form Styles
*/


/*
* Value Label Styles
*/

label {
  display: block;
  font-size: 0.8em;
  opacity: 0.9;
  margin-bottom: 3px;
}

div.label-value {
  margin-top: 0;
  margin-bottom: 10px;
}

div.label-value.large {
  font-size: 1.1em;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--rashtan-border-color);
  ;
}

.box div.label-value:last-child {
  border-bottom: none;
}

div.label-value.not-available {
  margin-top: 0;
  opacity: 0.6;
}

div.label-value.bold {
  font-weight: bold;
}

div.label-value .smaller {
  font-size: 0.8em;
}

/*
* END Value Label Styles
*/


.item-native {
  padding-inline-start: 0px !important;
  padding-left: 0px !important;
  padding-inline-end: 0px !important;
}

.app-logo {
  max-width: 210px;
}

.company-logo-select {
  border: var(--ion-color-primary) 2px solid;
  border-radius: 50%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  width: 160px;
  height: 160px;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
}

.logo-thumb {
    border: var(--ion-color-primary-contrast) 2px solid;
    --border-radius: 50%;
    margin-left: 15px;
}

.logo-menu-client {
    border: var(--ion-color-primary-contrast) 4px solid;
    --border-radius: 50%;
    margin: -25px auto 0 auto !important;
}

.logo-menu {
    --size: 144px;
    margin: 0 auto;
}

.lab-name {
  display: block;
  text-align: center;
}

.photo-image-logo-select { 
  margin: 45% auto; 
  font-size: 2em; 
  color: gray; 
}

ion-list {
  /* optional, but it needs when you use gradient as a background color.*/
  background: transparent;
}

.production-log-item {
    width: 100%;
}

ion-item.item.md.item-disabled.production-log-item {
    opacity: 0.9;
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary-contrast);
}

ion-menu.md ion-list-header,
ion-menu.md ion-item ion-icon {
  color: var(--ion-color-step-650, var(--ion-color-primary-contrast));
}

ion-menu.md ion-list-header,
ion-menu.md ion-item ion-icon {
  color: #fafafa;
}

.left-menu-icon {
  color: #fafafad5 !important;
}

ion-item.selected {
  /* color: var(--ion-color-primary); */
  /* font-weight: bold; */
}

ion-item.selected .left-menu-icon {
  /* color: var(--ion-color-primary) !important */
}


ion-list ion-item .dt-icon {
  margin: 0;
  margin-right: 10px;
}



.box {
  border-radius: 10px !important;
  -webkit-box-shadow: 0 10px 35px rgba(0, 173, 181, 0.14);
  box-shadow: 0 10px 35px rgba(0, 173, 181, 0.1);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: relative;
  background-color: #ffffff;
  z-index: 1;
  margin-bottom: 20px;
  margin-top: 0px;
  padding: 16px;
  z-index: 1;
  /* margin: 24px; */
}

ion-col .box {
  height: 100%;
}


.box h3:first-child {
  margin-top: 0;
  margin-left: 6px;
}

.no-upper-border-radius {
  border-radius: 0 0 10px 10px;
}

ion-col.box {
  margin: 10px;
  margin-top: 0;
}

ion-item,
ion-list {
  --ion-background-color: none;
}

ion-grid {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 600px) {
  ion-col.box {
    margin: 0 !important;
    margin-bottom: 20px !important;
  }
}

/*
 * Side Menu Styles
*/
/* side menu dark color */
ion-menu {
  width: 320px
}

ion-menu {
  --ion-background-color: var(--ion-color-primary);
  --ion-text-color: var(--ion-color-primary-contrast);
}

ion-menu.md ion-list-header {
  text-transform: uppercase;
  letter-spacing: 0.15em;
  opacity: 0.8;
}

ion-menu ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu ion-item svg {
  min-width: 25px;
}

ion-menu ion-item {
  --padding-start: 18px;
  border-radius: 0 50px 50px 15px;
}

/*
 * END Side Menu Styles
*/

/*
* Data Table Styles
*/

/* pagination style */
.rdt_Pagination {
  border-top-width: 0px !important;
  background: none !important;
}

.rdt_TableRow {
  border-radius: 15px !important;
}


/* column header height */
.rdt_TableHeadRow {
  min-height: 36px !important;
  opacity: .9;
}

/* disable hover effect in data table ion items */
.rdt_TableRow ion-item.item:hover {
  --ion-background-color: none;
  --background-hover-opacity: 0;
}

/* rows per page text color fix */
.rdt_Pagination option {
  color: var(--ion-text-color) !important;
}

/* row border */
.rdt_TableRow:not(:last-of-type) {
  border-bottom-color: var(--rashtan-border-color) !important;
}

.rdt_TableRow:hover {
  background-color: var(--ion-background-color);
}

@media only screen and (max-width: 600px) {

  /*hide column header on small screen*/
  .rdt_TableHead {
    display: none !important;
  }

  /* removing padding on small screens */
  .rdt_TableCell {
    padding: 0 !important;
  }

  /* remove left padding on small screens */
  .rdt_TableCell {
    padding-left: 0 !important;
  }
}

.rdt_TableRow ion-item .svg-inline--fa {
  margin-right: 6px !important;
  margin-left: 0px!important;
  width: 20px !important;
  text-align: center !important;
}

.cases-table-col-name .svg-inline--fa {
  /* width: initial!important; */
  /* margin-right: 5px!important; */
}

.bdkqKp {
  display: initial !important;
}

/* font in columns, matching with ionic style */
.rdt_TableCell {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-overflow: inherit;
  overflow: inherit;
  color: var(--ion-color-medium)
}

/*
* END Data Table Styles
*/

ion-content {
  --padding-bottom: 30px;
  --padding-end: 20px;
  --padding-start: 20px; 
  --padding-top: 10px;
}

.lower-top-padding {
  padding-top: 10px;
}


/* Full Calendar styles */

/* fix margin issues */
.fc .fc-daygrid-event-harness {
    margin-top: -27px;
}

.cal_list_items p[slot="start"] {
    margin-right: unset;
    margin-inline-end: 15px!important;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .cal_list_items  p[slot="start"] {
    margin-inline-end: 7px!important;
}
}

ion-label .svg-inline--fa {
  margin-right: 4px!important;
}

.pl_event {
  display: none;
}

.pl_item_notes {
  font-weight: normal;
}
@media only screen and (min-width: 600px) {
  .pl_event {
    display: block;
  border-top:5px solid #5494bc;
  }

  .case_event.finish_date {
  border-top:5px solid #72c0ce!important;
  }
  .case_event.tryout_date {
  border-top:5px solid #af9ece!important;
  }

 
}

 .case_dot.undefined {
    border-color:#77447e;
  }
.pl_event {
  margin: 0;
  padding-bottom: 5px;
}

.case_event {
  margin: 0;
  padding-bottom: 5px;
}
.fc svg:not(:root).svg-inline--fa {
    width: 14px;
    font-size: 10px;
    text-align: center;
}
.fc .fc-col-header-cell-cushion {
  color: var(--ion-text-color);
  font-weight: normal;
}

.fc-day-disabled {
  background: none !important;
}

.fc .fc-daygrid-day-number {
  color: var(--ion-text-color);
}

@media only screen and (max-width: 600px) {
  .fc .fc-daygrid-event-harness {
    /* position: relative; */
    display: inline-block;
  }

  .fc-event-time {
    display: none;
  }

  .fc-event-title {
    display: none;
  }

  .fc .fc-daygrid-day-number {
    padding: 4px;
    font-size: 18px;
    margin: 0 auto;
  }

  .fc .fc-col-header-cell-cushion {
    margin-bottom: 15px !important;
  }

  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    text-align: center;
  }

  .fc-theme-standard td,
  .fc-theme-standard th {
    border: none !important;
  }

  .fc-theme-standard .fc-scrollgrid {
    border: none !important;
  }

  .fc .fc-daygrid-body-natural .fc-daygrid-day-events {
    margin-bottom: 0 !important;
  }

  .fc th,
  .fc td {
    cursor: pointer;
  }

  .fc .fc-scrollgrid-section-sticky>* {
    background: none !important;
  }
}

.fc-daygrid-day-frame {
  cursor: pointer;
}

.fc a[data-navlink]:hover {
  text-decoration: none !important;
}

.fc a[data-navlink]:focus {
  outline: none !important;
}

.case-status {
  font-size: 0.8em;
}
.case-status .svg-inline--fa {
  margin: 0;
  margin-right: 1px!important;
}

.cal-event-title .svg-inline--fa {
  font-size: 0.8em;
  margin-right: 3px;
  margin-left:0;
}


.cal-event-title:hover {
  background-color: #78919221;
}

.cal-event-title {
  font-weight: bold;
  color: #6e6e6e;
}
.cal-event-title.smaller {
  font-size: 0.8em;
}

.case-page-title  {
  padding-left: 0;
}
.case-page-title .svg-inline--fa {
    margin-right: 6px;
}
.cal-list-case-title {
  margin-top:10px;
  display:block;
}

.cal-list-case-title .svg-inline--fa {
  margin-right: 3px;
}

.cal-event-sub-title {
  color: var(--ion-text-color);
}

.cal-event-sub-title,
.cal-event-title,
.cal-event-time-doctor {
  margin-left: 5px;
  display: block;
}


.finish-date-dot {
  color: var(--ion-color-primary) !important;
  border-color: var(--ion-color-primary) !important;
}
.tryout-dot {
  color: var(--ion-color-medium) !important;
  border-color: var(--ion-color-medium) !important;
}
.tryout-received-dot, .tryout-shipped-dot, .finish-date-made-dot {
  color: var(--ion-color-success) !important;
  border-color: var(--ion-color-success) !important;
}
.tryout-today-dot, .finish-date-today-dot {
  color: var(--ion-color-warning) !important;
  border-color: var(--ion-color-warning) !important;
}
.tryout-not-shipped-dot, .finish-date-missed-dot {
  color: var(--ion-color-danger) !important;
  border-color: var(--ion-color-danger) !important;
}

.fc .fc-daygrid-event-harness {
  overflow: hidden !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  overflow: hidden;
}

.fc-daygrid-dot-event {
  display: initial !important;
}

.cal-event-time-doctor {
  color: var(--ion-color-medium) !important;
  font-size: 0.9em;
}

@media only screen and (max-width: 600px) {

  .cal-event-sub-title,
  .cal-event-title,
  .cal-event-time-doctor {
    display: none
  }

  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin: 0 !important;
  }

  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    position: relative;
    min-height: 1em !important;
  }

  .fc-theme-standard td,
  .fc-theme-standard th {
    overflow: hidden;
    border-radius: 20px;
  }

  .fc .fc-daygrid-day-number {
    padding-top: 10px !important;
  }
}

@media only screen and (min-width: 600px) {
  .fc-daygrid-event-dot {
    display: none
  }
}

/* z index fox for sleected day */
.fc .fc-daygrid-event-harness {
  z-index: 100000;
}

.fc .fc-button-primary {
  color: var(--fc-button-text-color, #fff);
  background-color: var(--ion-color-primary) !important;
  border-color: transparent !important;
  border-radius: 0;
}

.fc-day-past {
  background-color: #f2f2f2;
}

.cal-event-title .svg-inline--fa {
  font-size: 0.8em;
  margin-right: 3px;
  margin-left:0;
}


/* hide today button on small screen */
@media only screen and (max-width: 600px) {
  .fc-today-button {
    display: none !important;
  }

  .fc-toolbar-title {
    font-size: 0.9em;
  }
}



/* END Full Calendar styles */



/* Title css */

/* fix icon size in the title  */
ion-title svg:not(:root).svg-inline--fa {
  font-size: 0.85em;
}




@media only screen and (max-width: 600px) {

  /* removing padding on small screens to make more space for title */
  ion-title {
    padding: 0;
  }
}

/* END Title css */

/* React select */

.rselect__menu {
  z-index: 999999999999999 !important;
}

.rselect__control {
  border-width: 1px !important;
  border-color: #cbd5e0 !important;
  padding: 5px 4px;
  background-color: rgb(255 255 255 / 0%) !important;
  border-radius: 8px !important;
  border-color: #e4e4e4 !important;
}

.rselect__placeholder {
  color: var(--ion-color-medium) !important;
  opacity: .9;
}

.rselect__control--is-focused {
  border-color: #e4e4e4 !important;
  box-shadow: none !important;
}

/* END React select styles */


/* Utilities */

.flex_hide_if_empty:empty {
  flex-grow: 0;
}

.text_center {
  text-align: center;
}

.iconBg {
  position: absolute;
  bottom: 30px;
  margin: 0 au;
  right: 30px;
  font-size: 500px;
  /* z-index: -100; */
  opacity: 0.024;
}

.case-flag- { 
  display:none! important
}
.case-flag-red {
  color: var(--case-flag-red) !important;
}
.case-flag-orange {
  color: var(--case-flag-orange) !important;
}
.case-flag-green {
  color: var(--case-flag-green) !important;
}
.case-flag-yellow {
  color: var(--case-flag-yellow) !important;
}
.case-flag-blue {
  color: var(--case-flag-blue) !important;
}
.case-flag-violet {
  color: var(--case-flag-violet) !important;
}
.case-flag-black {
  color: var(--case-flag-black) !important;
}

.flag-select-icon {
  margin-right: 10px;
  margin-top: 5px;
  cursor:pointer;
}

.flag-select-icon.selected {
 font-size: 1.8rem;
}
.flag-select-icon.not-selected {
 font-size: 1.2rem;
 opacity:0.5;
}

.invoiceIcon0 {
  color: var(--ion-color-danger)!important;
}

.invoiceIcon1 {
  color: var(--ion-color-warning)!important;
}

.invoiceIcon2 {
  color: var(--ion-color-success)!important;
}


.opacity-09 {
  opacity: 0.9;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-07 {
  opacity: 0.7;
}

.bold {
  font-weight: bold;
}

.smaller-font {
  font-size: 0.8rem!important;
}

.margin-top-6 {
  margin-top:6px!important;
}

@media only screen and (min-width: 960px) {
  .big-modal .modal-wrapper {
    width: 960px;
    height: 90%;
  }
}

.modal-wrapper {
  -webkit-box-shadow: 5px 5px 32px 24px rgba(0,0,0,0.19)!important; 
  box-shadow: 5px 5px 32px 24px rgba(0,0,0,0.19)!important;
}

.date-header {
    font-size: 14px;
    margin-top: 20px;
    /* color: #ffffff; */
    /* text-transform: uppercase; */
    font-family: 'OpenSans'!important;
    background: #00adb5;
    padding: 10px;
    border-radius: 3px;
    text-align:center;
}

.form-header {
    font-size: 14px;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 5px;
    margin-bottom: 10px;
    margin-top: 20px;
    color: #696969;
    text-transform: uppercase;
}

.form-header-2 {
    font-size: 14px;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 5px;
    margin-bottom: 5px;
    margin-top: 10px;
    color: #696969;
    text-transform: uppercase;
}

.select-full-upper {
    position: absolute;
    margin-top: 40%;
    margin-left: 42%;
}
.select-full-lower {
    position: absolute;
    margin-top: 80%;
    margin-left: 42%;
}
.unselect-tooth-arches {
    position: absolute;
    margin-top: 60%;
    margin-left: 42%;
}

@media only screen and (min-width: 1140px) {
  .big-modal .modal-wrapper {
    width: 1140px;
    height: 90%;
  }
}

ion-toolbar .svg-inline--fa {
  color: var(--ion-text-color);
  opacity: 0.8;
}

@media only screen and (max-width: 600px) {
  .case-notes-col {
    padding-left: 10px;
  }
}

@media only screen and (max-width: 600px) {

  /* bigger icon in the toolbar */
  ion-toolbar .svg-inline--fa {
    font-size: 1.25em;
    opacity: 0.9;
    margin-right: 0;
  }
}

@media only screen and (max-width: 992px) {
  ion-content main {
    padding-inline-start: 5px!important;
    padding-inline-end: 5px!important;
  }
  .box {
    padding: 10px 10px;
  }
  ion-content {
    --padding-bottom: 30px;
    --padding-end: 10px;
    --padding-start: 10px;
    --padding-top: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .header_toolbar2 {
  --min-height: 26px!important;
  }
  .header_toolbar2 ion-segment-button {
    min-width: 20px;
  }
  .header_toolbar2 ion-label {
    color: #ffffff;
    font-weight: 400 !important;
    font-size: 11px;
  }
  
}

@media only screen and (max-width: 600px) {
  /* remove padding for buttons icon */
  .buttonTextIcon .svg-inline--fa{
    margin-right: 0!important;
  }
}

:host(.item-disabled) {
  cursor: not-allowed;
  opacity: 0.7;
}

.additional-status-label {
  margin-left: 6px;
  font-size: 0.8em;
}

.shake:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.zoom-in-out {
  animation: zoominout 1s infinite;
}

@keyframes zoominout {
  0% {
    transform: scale(0.9);
  }

  30% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(0.9);
  }
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

/* END Utilities */

/* Dashboard card */
.dashboard_card_header {
  padding-top:6px;
  padding-bottom:0px;
}
.card-content-md p {

}
/* END Dashboard card */


/* Change toolbar on small screens */
@media only screen and (max-width: 992px) {
  ion-header ion-toolbar {
    --background: var(--ion-color-primary) !important;
    --color: #ffffff;
  }
  
  ion-header .segment-button-checked {
    background-color: rgb(0, 148, 156) !important;  /* 10% darker than primary */
  }

  ion-title {
    --color: #ffffff;
    color: #ffffff;
  }

  ion-toolbar ion-buttons .svg-inline--fa {
    color: #ffffff !important;
  }

  .tab-has-label ion-label {
    margin-top: 6px;
  }
}

.inlineSelectBtnFix {
  margin-top:40px;
}

.noRightMargin {
  margin-right:0!important;
}

.toothSelected, .toothNotSelected {
  padding: 3px;
  border-radius: 6px;
  border:3px solid rgb(190, 190, 190);
  margin: 4px;
  cursor: pointer!important;
  display:inline-block!important;
}

.toothSelected {
  border-color: var(--ion-color-primary);
}

.delete-extra-btn {
  margin-top:14px;
}

.label_style {
    --border-radius: 0px;
    --border-style: solid;
    --padding-top: 0px;
    --padding-bottom: 0px;
    --padding-end: 0px;
    --inner-padding-top: 0px;
    --inner-padding-bottom: 0px;
    --inner-padding-start: 0px;
    --inner-box-shadow: none;
    --detail-icon-color: initial;
    --detail-icon-font-size: 20px;
    --detail-icon-opacity: 0.25;
    --color-activated: var(--color);
    --color-focused: var(--color);
    --color-hover: var(--color);
    --ripple-color: currentColor;
    -webkit-font-smoothing: antialiased;
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
    --background-activated: transparent;
    --background-focused: currentColor;
    --background-hover: currentColor;
    --background-activated-opacity: 0;
    --background-focused-opacity: 0.12;
    --background-hover-opacity: 0.04;
    --transition: opacity 15ms linear, background-color 15ms linear;
    --border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
    --highlight-height: 2px;
    --highlight-color-focused: var(--ion-color-primary, #3880ff);
    --highlight-color-valid: var(--ion-color-success, #2dd36f);
    --highlight-color-invalid: var(--ion-color-danger, #eb445a);
    --show-full-highlight: 0;
    --show-inset-highlight: 0;
    --min-height: 55px;
    --border-width: 0;
    --inner-border-width: 0;
    --ion-background-color: none;
    --inner-padding-end: 0px;
    --padding-start: 0px;
    font-style: inherit;
    letter-spacing: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-align: inherit;
    flex: 1 1 0%;
    -webkit-tap-highlight-color: transparent;
    font-weight: 600 !important;
    font-family: var(--title-font) !important;
    visibility: inherit;
    --color: initial;
    display: block;
    color: var(--color);
    font-size: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    align-self: stretch;
    width: auto;
    max-width: 100%;
    transform-origin: left top;
    transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1), transform 150ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 5px;
    transform: translateY(40%) scale(0.75);
}

ion-segment{
    min-height: 10px;
}

/* antd START */

ion-item .ant-picker {
  margin-top: 5px;
}

.ant-picker {
  background: none!important;
  border-radius: 8px!important;
  border-color: rgb(228, 228, 228)!important;
  padding: 12px 14px!important;
  width: 100%;
}

.ant-btn-primary {
    color: #fff;
    height: 35px;
    width: 70px;
    border-color: var(--ion-color-primary);
    background: var(--ion-color-primary);
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
    color: #fff;
    border-color: var(--ion-color-primary-tint);
    background: var(--ion-color-primary-tint);
}

.ant-picker-cell-today {
    border-color: var(--ion-color-primary-tint);
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: var(--ion-color-primary);
}

@media (max-width: 400px) {
  .ant-picker-content th, .ant-picker-content td {
    min-width: 22px;
  }
  .ant-picker-cell .ant-picker-cell-inner {
      min-width: 22px;
  }
  .ant-picker-date-panel {
    width: 260px;
  }
  .ant-picker-time-panel {

  }
  .ant-picker-time-panel-column {
    width: 40px;
  }
  .ant-picker-date-panel .ant-picker-body {
    padding: 8px 6px;
  }
}
@media (max-width: 1140px) {
  .ant-picker-header > button {
    min-width: 2.4em;
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  .ant-picker-range-wrapper .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .ant-picker-range-wrapper .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .ant-picker-range-wrapper .ant-picker-panels > *:last-child {
    display: none;
  }

  .ant-picker-range-wrapper .ant-picker-panel-container, .ant-picker-footer {
    width: 280px !important;
  }

  .ant-picker-range-wrapper .ant-picker-footer-extra > div {
    flex-wrap: wrap !important; 
  } 
}

span.ant-select-selection-item {
    font-size: 14px;
}

.ant-select {
      width: 100%!important;
}
.ant-select-selector {
  background: none!important;
  border-radius: 8px!important;
  border-color: rgb(228, 228, 228)!important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 48px!important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  line-height:46px!important;
}

/* antd END */