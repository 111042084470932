/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
  font-family: OpenSans;
  src: url("../assets/fonts/OpenSans-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: OpenSans;
  src: url("../assets/fonts/OpenSans-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: OpenSans;
  src: url("../assets/fonts/OpenSans-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: OpenSans;
  src: url("../assets/fonts/OpenSans-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: OpenSans;
  src: url("../assets/fonts/OpenSans-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Lato";
  src: url('../assets/fonts/Lato-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: "Lato";
  src: url('../assets/fonts/Lato-Bold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: "Raleway";
  src: url('../assets/fonts/Raleway-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: "Raleway";
  src: url('../assets/fonts/Raleway-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: "Raleway";
  src: url('../assets/fonts/Raleway-SemiBold.ttf');
  font-weight: 600;
}




/** Ionic CSS Variables **/
:root {
  --title-font: "Raleway";
  --content-font: "OpenSans";
  --ion-font-family: var(--content-font);

  --ion-background-color: #fafafa ;
  --ion-toolbar-background: #fafafa;
  --ion-text-color: #393E46;

  --rashtan-border-color: #f1f1f1;

  --case-flag-red: #df1b1b;
  --case-flag-orange:#ff971f;
  --case-flag-green:#28b915;
  --case-flag-yellow:#d6de00;
  --case-flag-blue:#1687a7;
  --case-flag-violet:#8b5e83;
  --case-flag-black:#161616;

  --ion-color-primary: #00ADB5;
  --ion-color-primary-rgb: 0,173,181;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #00989f;
  --ion-color-primary-tint: #1ab5bc;


  --ion-color-secondary: #1eae91;
  --ion-color-secondary-rgb: 30, 174, 145;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1a9980;
  --ion-color-secondary-tint: #35b69c;

  --ion-color-tertiary: #6d75ca;
  --ion-color-tertiary-rgb: 109, 117, 202;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #6067b2;
  --ion-color-tertiary-tint: #7c83cf;

  --ion-color-success: #2aac69;
  --ion-color-success-rgb: 42, 172, 105;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #25975c;
  --ion-color-success-tint: #3fb478;

  --ion-color-warning: #e6b40f;
  --ion-color-warning-rgb: 230, 180, 15;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #ca9e0d;
  --ion-color-warning-tint: #e9bc27;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #525252;
  --ion-color-dark-rgb: 82, 82, 82;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #484848;
  --ion-color-dark-tint: #636363;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}